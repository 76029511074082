import React, { useEffect } from 'react';

const aboutLines = [
  "ربات روانشناس یک ربات کامل و پیشرفته در بستر تلگرام میباشد و در زمینه ارائه انواع مشاوره های فردی ، تحصیلی ، روابط عاطفی ، خانوادگی ، کودک و نوجوان ، شغلی و ... فعالیت دارد.",
  "هدف ما از توسعه و راه اندازی ربات روانشناس ارائه خدمات روانشناسی بدون نیاز به حضور و همینطور تا جای ممکن به صورت ناشناس و کاملا راحت برای کاربران بوده و سعی ما این است که بدون هیچ محدودیت زمان و مکان بتوانیم ، با یکی بهترین تیم های روانشناسی ، مشکلات شما را سامان ببخشیم.",
  'تیم ما تابع تمامی قوانین موجود در <span style="color:green">جمهوری</span> اسلامی <span style="color:red">ایران</span> و قوانین ذکر شده در ربات فعالیت و رفتار خواهد کرد.',
  "در صورت گزارش هر گونه تخلف که در راستای رعایت نکردن قوانین جامعه و ربات میباشد طبق قانون با فرد و نهاد متخلف برخورد خواهد شد."
];

const About = () => {
  useEffect(() => {
    const aboutList = document.getElementById("about-list");
    aboutList.innerHTML = ''; // Clear previous entries

    aboutLines.forEach((line, index) => {
      const li = document.createElement("li");
      li.innerHTML = line;
      aboutList.appendChild(li);
      setTimeout(() => {
        li.style.opacity = 1;
        li.style.maxHeight = "1000px";
      }, (index + 1) * 1000);
    });
  }, []);

  return (
    <div className="scrollable-content">
      <ul id="about-list" className="persian-text"></ul>
    </div>
  );
};

export default About;
