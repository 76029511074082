// src/components/Payment.js

import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles.css';

const Payment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [responseMessage, setResponseMessage] = useState('Loading...');
  const hasFetched = useRef(false); // Ref to ensure the request is made only once

  useEffect(() => {
    if (hasFetched.current) return; // If the request has already been made, do nothing
    hasFetched.current = true; // Mark that the request is being made

    const searchParams = new URLSearchParams(location.search);
    const authority = searchParams.get('Authority');

    if (authority) {
      axios.get(`https://telegram.ravan-shenas.com/verify-payment?Authority=${authority}`)
        .then(response => {
          if (!response.data.result.success
          ) {
            setResponseMessage('مشکلی در تایید تراکنش به وجود آمد');
          } else {
            setResponseMessage(response.data.result.firstTime ? `تراکنش شما  با کد پیگیری ${response.data.result.refId} به مبلغ ${new Intl.NumberFormat().format(parseInt(response.data.result.amount)/10)} تومان با موفقیت انجام و تایید شد و هم اکنون تعداد ${response.data.result.coinsCount} سکه به حساب شما در ربات افزوده شد`: `تراکنش شما با کد پیگیری ${response.data.result.refId} به مبلغ ${new Intl.NumberFormat().format(response.data.result.amount)} تومان با موفقیت انجام و قبلا تایید شده است`);
          }
        })
        .catch(error => {
          setResponseMessage('مشکلی در تایید تراکنش به وجود آمد');
        });
    } else {
      setResponseMessage('No authority parameter found.');
    }
  }, [location.search]);

  return (
    <>
      <div className="background-animation"></div>
      <div className="payment-container">
        <div className="payment-box">
          <h1>Ravan Shenas Robot</h1>
          <h2>{responseMessage}</h2>
          <button className="back-button" onClick={() => {
            window.location.href = 'https://t.me/RavanShenasRobot'
            navigate('/')
          }}>
            بازگشت به ربات <i className="fas fa-robot"></i>
          </button>
        </div>
      </div>
    </>
  );
}

export default Payment;
