// src/App.js

import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import About from './components/About';
import Payment from './components/Payment';
import './styles.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={
            <>
              <div className="background-animation"></div>
              <div className="centered-box">
                <h1>Ravan Shenas Robot</h1>
                <About />
                <div className="button-group">
                  <button className="support-button" onClick={() => window.location.href = 'https://t.me/SixOne9ine'}>
                    پشتیبانی <i className="fas fa-phone-alt"></i>
                  </button>
                  <button className="community-button" onClick={() => window.location.href = 'https://t.me/RavanShenasChannel'}>
                    کانال ما <i className="fab fa-telegram-plane"></i>
                  </button>
                </div>
                <button className="main-button" onClick={() => window.location.href = 'https://t.me/RavanShenasRobot?start=website'}>
                  رفتن به ربات <i className="fas fa-robot"></i>
                </button>
              </div>
            </>
          } />
          <Route path="/verify-payment" element={<Payment />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
